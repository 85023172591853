body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.viewing-host {
  color: #bdbdbd; }
  .viewing-host .presentation-dashboard .presentation-dashboard__wrapper {
    width: 100%; }
  .viewing-host .viewing-controls {
    width: calc(100vw - 40px);
    position: fixed;
    z-index: 40;
    left: 50%;
    transform: translateX(-50%);
    bottom: 71px;
    height: 62px;
    background-color: #fff;
    display: flex;
    border-radius: 2px;
    padding: 11px;
    box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.3), 0px 15px 12px rgba(0, 0, 0, 0.22); }
    .viewing-host .viewing-controls__block {
      display: flex;
      border-right: 1px solid #BDBDBD;
      padding: 0 20px !important; }
      .viewing-host .viewing-controls__block:first-child {
        flex-grow: 1; }
      .viewing-host .viewing-controls__block:last-child {
        border-right: none; }
    .viewing-host .viewing-controls .info-icon {
      color: #333;
      margin-top: 3px !important; }
    .viewing-host .viewing-controls__participants {
      margin: 0 12px;
      display: flex;
      flex-direction: row;
      align-items: center; }
      .viewing-host .viewing-controls__participants .status {
        display: inline-block;
        height: 14px;
        width: 14px;
        min-width: 14px;
        border-radius: 50%;
        background-color: #EB5757;
        margin: 2px 6px 2px 2px; }
        .viewing-host .viewing-controls__participants .status.connected {
          background-color: #6FCF97; }
    .viewing-host .viewing-controls__checkbox {
      margin: 0 4px;
      width: 36px;
      margin-top: -11px; }
      .viewing-host .viewing-controls__checkbox p {
        margin-top: -10px;
        margin-left: 2px;
        text-align: right; }
      .viewing-host .viewing-controls__checkbox > div > div > div {
        margin: 0 auto !important; }
      .viewing-host .viewing-controls__checkbox svg:nth-child(2) {
        fill: #008AAE !important; }
    .viewing-host .viewing-controls .tool-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #333; }
      .viewing-host .viewing-controls .tool-icon.selected {
        color: #008AAE; }
    .viewing-host .viewing-controls .disabled {
      color: rgba(51, 51, 51, 0.5); }
    .viewing-host .viewing-controls button {
      min-width: 0 !important;
      width: 36px !important;
      height: 36px !important;
      border-radius: 50% !important;
      margin: 0 4px !important; }
    .viewing-host .viewing-controls .mode-switch {
      cursor: pointer;
      text-align: center;
      margin: 0 4px;
      width: 55px; }
    .viewing-host .viewing-controls--open {
      bottom: 197px; }
  .viewing-host .viewing-controls.mobile-height {
    height: 65px; }
  @media (min-width: 768px) {
    .viewing-host .viewing-controls {
      width: auto;
      z-index: 15; } }
  @media (max-width: 770px) {
    .viewing-host .viewing-controls {
      overflow-x: scroll;
      overflow-y: hidden;
      height: 82px; } }
  @media (max-width: 675px) {
    .viewing-host .viewing-controls {
      bottom: 105px; }
    .viewing-host .viewing-controls--open {
      bottom: 242px; } }
  @media (max-width: 435px) {
    .viewing-host .viewing-controls {
      bottom: 135px; }
    .viewing-host .viewing-controls--open {
      bottom: 270px; } }
  .viewing-host .modal--startCoViewing .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center; }

body.ui-hidden .bottom-panel {
  display: none; }
