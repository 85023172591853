body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.viewing {
  overflow: hidden;
  color: #e8e8e8; }
  .viewing .viewing-controls {
    width: calc(100vw - 40px);
    position: fixed;
    z-index: 40;
    left: 50%;
    transform: translateX(-50%);
    bottom: 71px;
    height: 62px;
    background-color: #fff;
    display: flex;
    border-radius: 2px;
    padding: 11px;
    box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.3), 0px 15px 12px rgba(0, 0, 0, 0.22); }
    .viewing .viewing-controls__block {
      display: flex;
      border-right: 1px solid #BDBDBD;
      padding: 0 20px !important; }
      .viewing .viewing-controls__block:first-child {
        flex-grow: 1; }
      .viewing .viewing-controls__block:last-child {
        border-right: none; }
    .viewing .viewing-controls .info-icon {
      color: #333;
      margin-top: 3px !important; }
    .viewing .viewing-controls__participants {
      margin: 0 12px;
      display: flex;
      flex-direction: row;
      align-items: center; }
      .viewing .viewing-controls__participants .status {
        display: inline-block;
        height: 14px;
        width: 14px;
        min-width: 14px;
        border-radius: 50%;
        background-color: #EB5757;
        margin: 2px 6px 2px 2px; }
        .viewing .viewing-controls__participants .status.connected {
          background-color: #6FCF97; }
    .viewing .viewing-controls__checkbox {
      margin: 0 4px;
      width: 36px;
      margin-top: -11px; }
      .viewing .viewing-controls__checkbox p {
        margin-top: -10px;
        margin-left: 2px;
        text-align: right; }
      .viewing .viewing-controls__checkbox > div > div > div {
        margin: 0 auto !important; }
      .viewing .viewing-controls__checkbox svg:nth-child(2) {
        fill: #008AAE !important; }
    .viewing .viewing-controls .tool-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #333; }
      .viewing .viewing-controls .tool-icon.selected {
        color: #008AAE; }
    .viewing .viewing-controls .disabled {
      color: rgba(51, 51, 51, 0.5); }
    .viewing .viewing-controls button {
      min-width: 0 !important;
      width: 36px !important;
      height: 36px !important;
      border-radius: 50% !important;
      margin: 0 4px !important; }
    .viewing .viewing-controls .mode-switch {
      cursor: pointer;
      text-align: center;
      margin: 0 4px;
      width: 55px; }
    .viewing .viewing-controls--open {
      bottom: 197px; }
  .viewing .container {
    width: 100vw;
    position: fixed;
    z-index: 40;
    left: 0;
    height: 60px;
    background: linear-gradient(rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.5) 60px);
    bottom: 0;
    padding-top: 17px; }
    .viewing .container .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100vw;
      height: 27px;
      left: 0;
      bottom: 0;
      top: auto;
      overflow: hidden;
      color: #e8e8e8; }
      .viewing .container .header .content {
        flex: 1;
        flex-basis: 0.000000001px;
        overflow: hidden;
        margin-top: 0; }
        .viewing .container .header .content .title {
          color: white;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          user-select: none;
          pointer-events: none;
          font-size: 1em;
          padding-left: 30px;
          font-weight: 300;
          line-height: 19px; }
      .viewing .container .header .controls {
        margin-right: 16px;
        margin-top: 0;
        display: flex;
        flex-direction: row;
        align-items: center; }
        .viewing .container .header .controls .control {
          color: #fff;
          background-color: transparent; }
        .viewing .container .header .controls .imageIcon {
          width: 30px;
          height: 30px;
          cursor: pointer; }
        .viewing .container .header .controls .imageIcon.white {
          fill: white;
          fill: #fff; }
  .viewing .background {
    width: 100%;
    height: 100%;
    background: url("/static/home/viewing/background.jpg");
    background-size: cover;
    position: fixed;
    z-index: 40;
    display: block; }
  .viewing .modal.viewing-modal .modal__window {
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    transform: none;
    max-height: none;
    border-radius: 2px;
    overflow: hidden;
    padding: 0;
    background: #fff; }
    .viewing .modal.viewing-modal .modal__window .modal__body {
      padding: 0; }
    .viewing .modal.viewing-modal .modal__window .modal-title {
      background: #2E2E2E;
      padding: 22px;
      font-size: 20px;
      line-height: 23px;
      color: #fff;
      text-align: left;
      margin-top: -1px;
      padding-right: 55px; }
    .viewing .modal.viewing-modal .modal__window .modal-content {
      color: #828282;
      padding: 0 40px;
      min-height: 220px; }
      .viewing .modal.viewing-modal .modal__window .modal-content form {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: none; }
        .viewing .modal.viewing-modal .modal__window .modal-content form label {
          padding-top: 18px;
          padding-bottom: 10px;
          font-size: 24px;
          color: #BDBDBD; }
        .viewing .modal.viewing-modal .modal__window .modal-content form input[type="text"] {
          box-shadow: none;
          font-size: 26px;
          text-align: center;
          color: #2E2E2E;
          border: none;
          border-bottom: 2px solid #bdbdbd;
          max-width: 190px;
          margin: 0 auto 44px; }
          .viewing .modal.viewing-modal .modal__window .modal-content form input[type="text"].error {
            border-bottom: 2px solid #bdbdbd; }
      .viewing .modal.viewing-modal .modal__window .modal-content .tip {
        margin-bottom: 1rem; }
      .viewing .modal.viewing-modal .modal__window .modal-content .error {
        color: #f00; }
      .viewing .modal.viewing-modal .modal__window .modal-content .success {
        color: #27AE60; }
      .viewing .modal.viewing-modal .modal__window .modal-content .input-wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 153px; }
        .viewing .modal.viewing-modal .modal__window .modal-content .input-wrapper.higher {
          min-height: 220px; }
      .viewing .modal.viewing-modal .modal__window .modal-content .cta-button {
        flex-grow: 0;
        background: #ffff00;
        border-radius: 30px;
        padding: 14px 0;
        width: 220px;
        border: none;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        cursor: pointer;
        margin-bottom: 25px; }
    .viewing .modal.viewing-modal .modal__window .modal-secondary-content {
      background: #F2F2F2; }
  .viewing .viewing-controls.mobile-height {
    height: 65px; }
  @media (max-width: 760px) {
    .viewing .viewing-controls {
      overflow-x: scroll;
      overflow-y: hidden;
      height: 82px; } }
  @media (max-width: 675px) {
    .viewing .viewing-controls {
      bottom: 105px; }
    .viewing .viewing-controls--open {
      bottom: 242px; } }
  @media (max-width: 435px) {
    .viewing .viewing-controls {
      bottom: 135px; }
    .viewing .viewing-controls--open {
      bottom: 270px; } }
  @media (min-width: 768px) {
    .viewing .viewing-controls {
      width: auto;
      z-index: 15; }
    .viewing .modal.viewing-modal .modal__window {
      width: 450px;
      height: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
      .viewing .modal.viewing-modal .modal__window .modal__body {
        width: 100%; } }

body.ui-hidden .container {
  display: none; }
