body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.pagination {
  display: flex;
  text-align: center;
  max-width: 400px;
  margin: 0 auto; }
  .pagination .previous, .pagination .next {
    display: none; }
  .pagination li {
    display: inline-block;
    margin: 17px;
    width: 10px; }
    .pagination li a {
      color: white;
      font-weight: 300;
      transition: .2s all;
      transform: translateY(5px); }
      .pagination li a:focus {
        outline: none; }
  .pagination li.selected a {
    color: #008aae !important;
    transform: scale(1.5);
    font-weight: 600; }
